import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { NbAuthService, NbAuthSimpleToken } from '@nebular/auth';
import { environment } from 'environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: NbAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let authRequest;

    this.authService.onTokenChange().subscribe((token: NbAuthSimpleToken) => {
      if (token.isValid()) {
        const returndToken = token.getValue();

        authRequest = req.clone({
          setHeaders: {
            Authorization: `${
              environment.tokenTypeBearer ? 'Bearer ' : ''
            }${returndToken}`,
          },
        });
      } else {
        authRequest = req.clone({
          setHeaders: {
            Authorization: `${environment.tokenTypeBearer ? 'Bearer ' : ''}${
              environment.API_KEY
            }`,
          },
        });
      }
    });

    return next.handle(authRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>');
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // 401 handled in auth.interceptor
          console.log('Error!!!');
          localStorage.removeItem('auth_app_token');
          this.router.navigate(['auth/login']);
        }
        return throwError(error);
      }),
    );
  }
}
