import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Created with ♥ by
      <b><a href="https://softweb.gr/el/" target="_blank">SoftWeb</a></b>
      {{ year }}
    </span>
    <div class="socials">
      <a
        href="https://www.facebook.com/Softweb-Adaptive-IT-Solutions-102716123513/"
        target="_blank"
        class="ion ion-social-facebook"
      ></a>
      <a
        href="https://twitter.com/Softweb_spot"
        target="_blank"
        class="ion ion-social-twitter"
      ></a>
      <a
        href="https://www.linkedin.com/company/softweb/"
        target="_blank"
        class="ion ion-social-linkedin"
      ></a>
    </div>
  `,
})
export class FooterComponent {
  year = new Date().getFullYear();
}
