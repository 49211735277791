export default {
  productionRequestUrl:
    'https://sfera-backend.dd.softwebpages.com/api/v1/admin',
  devRequestUrl: 'https://sfera-backend.dd.softwebpages.com/api/v1/admin',
  tokenTypeBearer: false, // type of the Auth token is bearer
  API_KEY: '8tWOPrMgbyPOhvietzMWuSmSYf-pMt_Of8jZJWorqry5G3KYBPhlL5X3CL9T3oOE',
  ID: '_id', // id or _id,

  licenceID: '', // the id to take the activated modules,
  image_field_name: 'image', // το ονομα που εχει το πεδιο με τις εικονες στο project
};
